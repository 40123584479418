import { getVideoById, Video } from '../../utils/api/videoApi';
import { Color, colors } from '../../utils/styleguide';
import HeroStandardLayout from '../component-library/layout/HeroSection/HeroStandardLayout';
import Image, { ImageStyleProps } from './Image';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText/RichText';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';
import sectionToCompatibleServerSideProps from './util/sectionToCompatibleServerSideProps';

type ServerSideProps = {
  landscapeBackgroundVideo?: Video;
  portraitBackgroundVideo?: Video;
};

const HeroStandardSection: PageSectionComponent<ServerSideProps> = ({
  section,
  serverSideProps: { landscapeBackgroundVideo, portraitBackgroundVideo },
}) => {
  const {
    fields: { image: imageDeprecated },
  } = section;
  const {
    id,
    description,
    title,
    button,
    backgroundImage,
    backgroundColor,
    backgroundLinear,
    backgroundImageLandscape,
    backgroundImagePortrait,
    backgroundOverlay,
    logos,
    variant,
    settings,
  } = sectionToCompatibleProps({
    section,
  });

  const logosList = logos?.map((section) => ({
    title: section?.fields.title || '',
    // eslint-disable-next-line react/display-name
    Image: (props: ImageStyleProps) => <Image asset={section?.fields.image} {...props} />,
  }));

  return (
    <HeroStandardLayout
      id={id}
      title={
        <RichText document={title} textColor={colors[(settings?.titleColor as Color) || 'white']} />
      }
      description={<RichText document={description} textColor={colors.white} />}
      image={(props: ImageStyleProps) => (
        <Image
          asset={{
            // imageDeprecated was used as background image
            landscape: backgroundImageLandscape || backgroundImage || imageDeprecated,
            portrait: backgroundImagePortrait,
          }}
          {...props}
        />
      )}
      CTAText={button?.label}
      CTAPrompt={button?.prompt}
      CTAColor={button?.colorContext || 'purple'}
      CTAVariant={button?.variant || 'solid'}
      href={button?.href}
      backgroundColor={backgroundColor}
      backgroundLinear={backgroundLinear}
      landscapeBackgroundVideo={landscapeBackgroundVideo}
      portraitBackgroundVideo={portraitBackgroundVideo}
      logos={logosList}
      variant={variant}
      overlay={backgroundOverlay}
    />
  );
};

HeroStandardSection.getCustomServerSideProps = async ({ section }) => {
  const { backgroundVideoIdLandscape, backgroundVideoIdPortrait } =
    sectionToCompatibleServerSideProps({ section });
  const [portraitBackgroundVideo, landscapeBackgroundVideo] = await Promise.all([
    getVideoById(backgroundVideoIdPortrait),
    getVideoById(backgroundVideoIdLandscape),
  ]);
  return { portraitBackgroundVideo, landscapeBackgroundVideo };
};

export default HeroStandardSection;
