import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, spacing } from '../../../../utils/styleguide';
import { ImageStyleProps } from '../../../contentful-elements/Image';
import PropsComponent from '../../types/PropsComponent';
import { Variant } from '../../types/VariantTypes';

const handleVariantImageColor = ({ variant }: { variant?: LogoVariants }) => {
  switch (variant) {
    case 'light':
      return css`
        filter: brightness(0) invert(1);
      `; // colors.white independant of image color
    case 'dark':
    case 'kurppa-dark':
      return css`
        filter: brightness(0);
      `; // colors.black independant of image color
  }
};

const LogoBlock = styled.div`
  margin-top: ${({ noMargin }: { noMargin: boolean }) => (noMargin ? 0 : `${spacing[4]}px`)};
  display: flex;
  gap: ${spacing[4]}px;

  ${breakpoints.tablet} {
    margin-top: ${({ noMargin }: { noMargin: boolean }) => (noMargin ? 0 : `${spacing[6]}px`)};
    gap: ${spacing[6]}px;
  }
`;

type LogoVariants = Variant | 'standard';
type LogosProps = {
  logos: Logo[];
  variant?: LogoVariants;
  logoHeights?: LogoHeights;
  noMargin?: boolean;
};

export const Logos = ({ logos, variant = 'light', logoHeights, noMargin = false }: LogosProps) => {
  if (!logos || !logos.length) {
    return null;
  }

  if (logos.length === 1)
    return <SingleLogo logo={logos[0]} variant={variant} logoHeights={logoHeights} />;

  return (
    <LogoBlock noMargin={noMargin}>
      {logos.map((logo, index) => (
        <Logo key={index} logo={logo} variant={variant} logoHeights={logoHeights} />
      ))}
    </LogoBlock>
  );
};

export type Logo = {
  title: string;
  Image: PropsComponent<ImageStyleProps>;
};

type LogoHeights = {
  mobile: number;
  tablet?: number;
};

type LogoProps = {
  logo: Logo;
  variant?: LogoVariants;
  logoHeights?: LogoHeights;
};

const SingleLogo = ({
  logo,
  variant = 'light',
  logoHeights = {
    mobile: 64,
    tablet: 56,
  },
}: LogoProps) => {
  const Image = logo.Image;

  return (
    <Image
      wrapperCss={css`
        ${handleVariantImageColor({ variant })}
        height: ${logoHeights.mobile}px;
        margin-top: ${spacing[4]}px;

        ${breakpoints.tablet} {
          margin-top: ${spacing[6]}px;
          height: ${logoHeights.tablet}px;
        }
      `}
      css={css`
        height: 100%;
        max-width: 100%;
        object-fit: contain;
      `}
      key={logo.title}
    />
  );
};
const Logo = ({
  logo,
  variant = 'light',
  logoHeights = {
    mobile: 20,
    tablet: 24,
  },
}: LogoProps) => {
  const Image = logo.Image;

  return (
    <Image
      wrapperCss={css`
        ${handleVariantImageColor({ variant })}
        height: ${logoHeights.mobile}px;

        ${breakpoints.tablet} {
          height: ${logoHeights.tablet ?? logoHeights.mobile}px;
        }
      `}
      css={css`
        height: 100%;
        max-width: 100%;
        object-fit: contain;
      `}
      key={logo.title}
    />
  );
};
