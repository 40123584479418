import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Video } from '../../../../utils/api/videoApi';
import { getStreamingSrc, VIDEO_ORIENTATIONS } from '../../../../utils/helpers/video';
import { breakpoints, spacing } from '../../../../utils/styleguide';
import { ImageStyleProps } from '../../../contentful-elements/Image';
import ButtonPromptWrapper from '../../ButtonPromptWrapper';
import PrimaryButton, { ButtonColor } from '../../buttons/PrimaryButton';
import HeadlinerBackground from '../../HeadlinerBackground';
import BackgroundVideoSection from '../../page-sections/BackgroundVideoSection';
import PropsComponent from '../../types/PropsComponent';
import { Variant } from '../../types/VariantTypes';
import { Col, Row } from '../12ColGridLayout';
import { Logo, Logos } from './Logos';

type ContentProps = {
  hasVideo: boolean;
};

const Content = styled.div<ContentProps>`
  min-height: ${({ hasVideo }) => (hasVideo ? '100%' : '86vh')};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${spacing[9]}px ${spacing[4]}px;

  ${breakpoints.desktop} {
    padding: ${spacing[11]}px;
  }
`;

const RowDense = styled(Row)`
  grid-auto-flow: dense;
  align-items: flex-end;
`;

const TagWrapper = styled.div`
  margin-bottom: ${spacing[4]}px;
`;

const TitleWrapper = styled.div``;

const DescriptionWrapper = styled.div`
  margin-top: ${spacing[4]}px;

  ${breakpoints.desktop} {
    margin-top: ${spacing[6]}px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
`;

const ButtonWrapper = styled(ButtonPromptWrapper)`
  margin-top: ${spacing[6]}px;
`;

type HeroStandardLayoutProps = {
  id?: string;
  title?: ReactNode;
  description?: ReactNode;
  image?: PropsComponent<ImageStyleProps>;
  CTAColor?: ButtonColor;
  CTAVariant?: 'solid' | 'outline';
  CTAText?: ReactNode;
  CTAPrompt?: ReactNode | string;
  href?: string;
  backgroundColor?: string;
  backgroundLinear?: string;
  backgroundBlend?: string;
  landscapeBackgroundVideo?: Video;
  portraitBackgroundVideo?: Video;
  logos?: Logo[];
  variant?: Variant;
  tag?: ReactNode;
  overlay?: number;
};

const HeroStandardLayout = ({
  id,
  title,
  description,
  image,
  CTAText,
  CTAPrompt,
  CTAColor,
  CTAVariant,
  href,
  backgroundColor,
  backgroundLinear,
  landscapeBackgroundVideo,
  portraitBackgroundVideo,
  logos,
  variant,
  tag,
  overlay = 30,
}: HeroStandardLayoutProps) => {
  const hasVideo = !!(landscapeBackgroundVideo && portraitBackgroundVideo);
  const content = (
    <Content hasVideo={hasVideo}>
      <RowDense noGutter noMargin>
        <Col desktop="span 8">
          {tag && <TagWrapper>{tag}</TagWrapper>}
          {title && <TitleWrapper>{title}</TitleWrapper>}
          {description && <DescriptionWrapper>{description}</DescriptionWrapper>}
          {logos && (
            <LogoWrapper>
              <Logos logos={logos} variant={variant} />
            </LogoWrapper>
          )}
          {CTAText && (
            <ButtonWrapper prompt={CTAPrompt}>
              <PrimaryButton href={href} color={CTAColor} variant={CTAVariant}>
                {CTAText || 'Learn more'}
              </PrimaryButton>
            </ButtonWrapper>
          )}
        </Col>
      </RowDense>
    </Content>
  );

  return (
    <HeadlinerBackground
      id={id}
      backgroundColor={image ? undefined : backgroundColor}
      backgroundLinear={backgroundLinear}
      Image={image}
      overlay={overlay}
    >
      {hasVideo ? (
        <BackgroundVideoSection
          videoLargeScreenUrl={getStreamingSrc({
            video: landscapeBackgroundVideo,
            orientation: VIDEO_ORIENTATIONS.LANDSCAPE,
          })}
          videoSmallScreenUrl={getStreamingSrc({
            video: portraitBackgroundVideo,
            orientation: VIDEO_ORIENTATIONS.PORTRAIT,
          })}
          posterLargeScreenUrl={landscapeBackgroundVideo.metaData.posterSrc}
          posterSmallScreenUrl={portraitBackgroundVideo.metaData.posterSrc}
          aspectRatios={{ desktop: 0, tablet: 0, mobile: 0 }}
          showControls={false}
          hasOverlay
        >
          {content}
        </BackgroundVideoSection>
      ) : (
        <>{content}</>
      )}
    </HeadlinerBackground>
  );
};

export default HeroStandardLayout;
